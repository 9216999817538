  /**
   * 处理下载Execl
   * @param {file}  文件对象
   * @param {filename}  需要保存的文件对象名称
   * 保存的文件格式为：filename-年月日.xlsx
   */
   export function operateFile (file,filename) {
    let fileName = filename + "-" + new Date().getFullYear() + '' +( new Date().getMonth()+1)  + '' +new Date() .getDate()+".xlsx";
    let blobObject = new Blob([file], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}); 
    //是IE浏览器
    if(!!window.ActiveXObject || "ActiveXObject" in window){
      window.navigator.msSaveOrOpenBlob(blobObject,fileName);
    }else{//火狐谷歌都兼容
    //模板中要有一个预定义好的a标签
      let link = document.getElementById('a_id')
      link.href = URL.createObjectURL(blobObject);
      link.download = fileName
      link.click();
    }
  }
    export default{
    operateFile: operateFile,
}
