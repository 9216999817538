<template>
    <div class="business-nav-box">
            <ul class="business-nav">
                <li  v-for="(tab, i) in tabs" :class="{'active': value===i}" :key="i" @click="tabClick(i)">{{tab.name}}</li>
                <!-- <li>失效券</li> -->
            </ul>
            <div>
                <!-- <div class="JumpCoupon" @click="jumpCoupon"><i class="iconfont iconyouhuiquan"></i>购买优惠券<em>+</em></div> -->
                <slot name="right"></slot>
            </div>
        </div>
</template>

<script>
	export default {
		props:{
			tabs: { // 支持格式: ['全部', '待付款'] 或 [{name:'全部'}, {name:'待付款'}]
				type: Array,
				default(){
					return []
				}
			},
			value: { // 当前显示的下标 (使用v-model语法糖: 1.props需为value; 2.需回调input事件)
				type: [String, Number],
				default: 0
			},
		
		},
		data() {
			return {
			
			}
		},
	
		methods: {

			tabClick(i){
				
				if(this.value!=i){
					this.$emit("input",i);
					this.$emit("change",i);
				}
			},
		
		},
		mounted() {
			
		}
	}
</script>



<style lang="less" scoped>
.business-nav-box{
    width: 100%;
    padding: 0 30px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    
    ul{
        display: flex;
        padding: 0;
        li{
            margin-right: 20px;
            position: relative;
            display: block;
            height: 28px;
            line-height: 28px;
            color: #333;
            text-align: center;
            background-color: #f1f5f9;
            border-radius: 18px;
            padding: 0 26px;
            font-size: 14px;
            cursor: pointer;
            transition:all .4s;
            &.active,&:hover{
                color: #fff;
                background-color: #1261ff;
                font-weight: 600;
            }
        }
    }
}
</style>