<template>
  <div>
    <!-- <a-table
      :columns="columns"
      rowKey="wguid"
      :data-source="workdataList[0]"
      :pagination="pagination"
      :loading="loading"
      @change="handleTableChange"
    >
      <a id="a_id"></a>
    </a-table> -->
    <a id="a_id"></a>
    <div class="top">
      <div class="reload" @click="reload">
        <a-icon type="reload" style="font-size: 16px; margin-right: 5px" />刷新
      </div>
      <div class="download" @click="download">
        <a-icon
          type="download"
          style="font-size: 16px; margin-right: 5px"
        />导出
      </div>
    </div>
    <me-tabs v-model="tabIndex" :tabs="tabs" @change="tabChange"></me-tabs>
    <div class="scroll-tr">
      <div class="cus-tr cus-th" v-if="workdataList.length > 0">
        <!-- <div class="cus-td">序号</div> -->
        <div
          class="cus-td"
          v-for="(item, index) in workdataList[0].wcontent"
          :key="index"
        >
          {{ item.fieldname }}
        </div>
      </div>

      <a-list
        item-layout="horizontal"
        :loading="loading"
        :data-source="workdataList"
        :pagination="{ ...paginationProps, current: currentPage }"
      >
        <a-list-item slot="renderItem" slot-scope="item">
          <div class="cus-tr">
            <!-- {{item}} -->
            <!-- <div class="cus-td">{{ index + 1 }}</div> -->
            <div class="cus-td" v-for="(val, key) in item.wcontent" :key="key">
              {{ val.fieldvalue ? val.fieldvalue : "-" }}
            </div>
          </div>
        </a-list-item>
      </a-list>
    </div>
  </div>
</template>
<script>
// const columns = [
//   {
//     title: "订单号",
//     dataIndex: "odno",
//     width: "10%",
//   },
//   {
//     title: "类型",
//     dataIndex: "ordertype",
//     width: "20%",
//   },
//   {
//     title: "实际消费金额",
//     dataIndex: "actualamount",
//     width: "20%",
//   },
// ];
import { getWorkver, getWorkFormData, getExportexcel } from "@/api/works";
import { mapGetters } from "vuex";
import meTabs from "@/components/meTabs";
import {operateFile} from "@/utils/tools";
export default {
  data() {
    return {
      workverList: [],
      workdataList: [],
      tabs: [],
      workno: "",
      wkversion: "",
      tabIndex: 0,
      couponlist: [],
      currentPage: 1, // 当前页
      total: 0,
      pageSize: 14,
      loading: false,
    };
  },
  components: {
    meTabs,
  },
  computed: {
    ...mapGetters(["ossUrl"]),
    paginationProps() {
      const _this = this;
      return {
        // showQuickJumper: true,
        pageSize: _this.pageSize,
        total: _this.total,
        onChange(page, pageSize) {
          console.log(page, pageSize);
          _this.loading = true;
          _this.currentPage = page;
          //   _this.tabChange()
        },
      };
    },
  },
  methods: {
    tabChange() {
      this.loading = true;
      this.wkversion = this.tabs[this.tabIndex].wkversion;
      this.workData();
    },
    reload() {
      this.loading = true;
      this.workData(this.wkversion);
    },
    download() {
      let parameter = {
        workno: this.workno,
        wkversion: this.wkversion ,
      };
      getExportexcel(parameter)
        .then((data) => {
         
          operateFile(data,this.workno)


        })
        .catch(() => {});
    },
    workver(workno) {
      let parameter = {
        workno: workno,
      };
      getWorkver(parameter)
        .then((Data) => {
          // console.log(Data.data)
          let tab = [];
          // let
          for (var i = 0; i < Data.data.length; i++) {
            console.log(Data.data[i].wkversion);
            let arrStr = {
              value: 0,
              name: "第" + Data.data[i].wkversion + "版本",
              wkversion: Data.data[i].wkversion,
            };
            tab.push(arrStr);
            // tab[i].name=Data.data[0].wkversion
            // console.log(tab)
          }
          console.log(tab);
          this.tabs = tab;
          this.workverList = Data;
          this.wkversion = Data.data[0].wkversion;
          this.workData();
        })
        .catch(() => {});
    },
    workData() {
      console.log("888");

      let parameter = {
        wkid: this.workno,
        wkversion: this.wkversion,
      };
      getWorkFormData(parameter)
        .then((curPageData) => {
          // this.workdataList = curPageData
          this.workdataList = curPageData.data;
          this.loading = false;
          console.log(curPageData.length);
        })
        .catch(() => {});
    },
  },
  mounted() {
    this.workno = this.$route.params.workno;
    this.workver(this.workno);
    // this.workData(2)
  },
};
</script>
<style scoped>
.ant-list-split .ant-list-item {
  border: none;
  padding: 0;
}
</style>
<style lang="less" scoped>
.top {
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
  .reload,
  .download {
    cursor: pointer;
  }
}
.cus-tr {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #e8e8e8;
  &:hover {
    .cus-td {
      background: #e6f7ff;
    }
  }
  &.cus-th {
    // height: 40px;
    // line-height: 40px;
    // padding: 16px 0;
    background: #fafafa;
    .cus-td {
      border: none;
    }
  }
  .cus-td {
    width: 20%;
    cursor: pointer;
    text-align: center;
    padding: 16px 0;
  }
}
</style>