import request from '@/utils/request'

const workApi = {
    onlinework: '/api/auth/onlinework',
    offlinework:'/api/auth/offlinework',
    deletework:'/api/auth/deletework',
    pointsrule:'/api/auth/showpointsrule',
    removedads:'/api/auth/removedads',
    workver:'/api/auth/getworkver',//作品版本
    workFormData:'/api/auth/showFormData',//作品数据
    exportexcel:'/api/auth/exportpcexcel',
    createPoster:'/api/auth/usepostertpl',//创建海报

}

export default workApi

export function getOnlinework(parameter) {
    return request({
        url: workApi.onlinework,
        method: 'post',
        data: parameter
    })
}

export function getOfflinework(parameter) {
    return request({
        url: workApi.offlinework,
        method: 'post',
        data: parameter
    })
}

export function getDeletework(parameter) {
    return request({
        url: workApi.deletework,
        method: 'post',
        data: parameter
    })
}

export function getPointsrule(parameter) {
    return request({
        url: workApi.pointsrule,
        method: 'get',
        params: parameter
    })
}

export function PostRemovedads(parameter) {
    return request({
        url: workApi.removedads,
        method: 'post',
        data: parameter
    })
}

export function getWorkver(parameter) {
    return request({
        url: workApi.workver,
        method: 'get',
        params: parameter
    })
}

export function getWorkFormData(parameter) {
    return request({
        url: workApi.workFormData,
        method: 'get',
        params: parameter
    })
}

export function getExportexcel(parameter) {
    return request({
        url: workApi.exportexcel,
        method: 'get',
        responseType:'blob',
        params: parameter
    })
}

export function PostCreatePoster(parameter) {
    return request({
        url: workApi.createPoster,
        method: 'post',
        data: parameter
    })
}